
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as helpgTNWcaY3gjMeta } from "/vercel/path0/pages/help.vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as payment1M3Tpu2mWNMeta } from "/vercel/path0/pages/invoices/[invoice_id]/payment.vue?macro=true";
import { default as index3C7vuDtYoQMeta } from "/vercel/path0/pages/invoices/index.vue?macro=true";
import { default as indexoOBDY93e9EMeta } from "/vercel/path0/pages/login/index.vue?macro=true";
import { default as reset_45passwordXdXGqhiokHMeta } from "/vercel/path0/pages/login/reset-password.vue?macro=true";
import { default as _91id_93Kw8pDxaClKMeta } from "/vercel/path0/pages/prescriptions/[id].vue?macro=true";
import { default as createpI41FH0VbhMeta } from "/vercel/path0/pages/prescriptions/create.vue?macro=true";
import { default as indexe0TBfXSzksMeta } from "/vercel/path0/pages/prescriptions/index.vue?macro=true";
import { default as reportingldlYHKxnK8Meta } from "/vercel/path0/pages/reporting.vue?macro=true";
import { default as settingsyZZu4Rd1aAMeta } from "/vercel/path0/pages/settings.vue?macro=true";
import { default as clinicsVWcz6RuZWMeta } from "/vercel/path0/pages/signup/clinic.vue?macro=true";
import { default as identificationlN7wbjqP18Meta } from "/vercel/path0/pages/signup/identification.vue?macro=true";
import { default as indexLAWGSf2MvbMeta } from "/vercel/path0/pages/signup/index.vue?macro=true";
import { default as prescriberhebVUVWOiVMeta } from "/vercel/path0/pages/signup/prescriber.vue?macro=true";
import { default as successG3D1SnK9TzMeta } from "/vercel/path0/pages/signup/success.vue?macro=true";
import { default as supportJTqUQCOdxvMeta } from "/vercel/path0/pages/support.vue?macro=true";
import { default as terms_45conditionsvAyA1KQpqLMeta } from "/vercel/path0/pages/terms-conditions.vue?macro=true";
export default [
  {
    name: "help",
    path: "/help",
    component: () => import("/vercel/path0/pages/help.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/vercel/path0/pages/index.vue")
  },
  {
    name: "invoices-invoice_id-payment",
    path: "/invoices/:invoice_id()/payment",
    meta: payment1M3Tpu2mWNMeta || {},
    component: () => import("/vercel/path0/pages/invoices/[invoice_id]/payment.vue")
  },
  {
    name: "invoices",
    path: "/invoices",
    component: () => import("/vercel/path0/pages/invoices/index.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: indexoOBDY93e9EMeta || {},
    component: () => import("/vercel/path0/pages/login/index.vue")
  },
  {
    name: "login-reset-password",
    path: "/login/reset-password",
    meta: reset_45passwordXdXGqhiokHMeta || {},
    component: () => import("/vercel/path0/pages/login/reset-password.vue")
  },
  {
    name: "prescriptions-id",
    path: "/prescriptions/:id()",
    component: () => import("/vercel/path0/pages/prescriptions/[id].vue")
  },
  {
    name: "prescriptions-create",
    path: "/prescriptions/create",
    component: () => import("/vercel/path0/pages/prescriptions/create.vue")
  },
  {
    name: "prescriptions",
    path: "/prescriptions",
    component: () => import("/vercel/path0/pages/prescriptions/index.vue")
  },
  {
    name: "reporting",
    path: "/reporting",
    component: () => import("/vercel/path0/pages/reporting.vue")
  },
  {
    name: "settings",
    path: "/settings",
    component: () => import("/vercel/path0/pages/settings.vue")
  },
  {
    name: "signup-clinic",
    path: "/signup/clinic",
    meta: clinicsVWcz6RuZWMeta || {},
    component: () => import("/vercel/path0/pages/signup/clinic.vue")
  },
  {
    name: "signup-identification",
    path: "/signup/identification",
    meta: identificationlN7wbjqP18Meta || {},
    component: () => import("/vercel/path0/pages/signup/identification.vue")
  },
  {
    name: "signup",
    path: "/signup",
    meta: indexLAWGSf2MvbMeta || {},
    component: () => import("/vercel/path0/pages/signup/index.vue")
  },
  {
    name: "signup-prescriber",
    path: "/signup/prescriber",
    meta: prescriberhebVUVWOiVMeta || {},
    component: () => import("/vercel/path0/pages/signup/prescriber.vue")
  },
  {
    name: "signup-success",
    path: "/signup/success",
    meta: successG3D1SnK9TzMeta || {},
    component: () => import("/vercel/path0/pages/signup/success.vue")
  },
  {
    name: "support",
    path: "/support",
    component: () => import("/vercel/path0/pages/support.vue")
  },
  {
    name: "terms-conditions",
    path: "/terms-conditions",
    meta: terms_45conditionsvAyA1KQpqLMeta || {},
    component: () => import("/vercel/path0/pages/terms-conditions.vue")
  }
]